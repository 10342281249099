import Cookies from 'js-cookie';
import axios from 'axios';

axios.interceptors.request.use(config => {
    const myCookieValue = Cookies.get('cw_d_session_info');
    if (myCookieValue) {
        config.headers.scook = myCookieValue;
    }
    config.timeout = 60000;
    return config;
});